<template>
    <!-- BaseFormActions.vue -->
    <div>
        <!-- Secondary Button -->
        <v-btn
            v-if="!hideSecondaryButton"
            :color="secondaryButtonColor"
            :disabled="secondaryButtonDisabled || loading"
            class="pr-2"
            @click="handleSecondaryFormEvent"
        >
            {{ secondaryButtonText }}
            <v-icon v-if="secondaryButtonIcon" class="pl-3">{{ secondaryButtonIcon }}</v-icon>
        </v-btn>
        <!-- Primary Button -->
        <v-btn
            v-if="!hidePrimaryButton"
            :color="primaryButtonColor"
            :disabled="primaryButtonDisabled"
            :loading="loading"
            class="ml-2"
            @click="handlePrimaryFormEvent"
        >
            {{ primaryButtonText }}
            <v-icon v-if="primaryButtonIcon" class="pl-3">{{ primaryButtonIcon }}</v-icon>
        </v-btn>
    </div>
</template>

<script>
    // Mixins
    import BaseFormEventsMixin from '../mixins/base-form-events.mixin'

    /**
     * Action buttons to be used in forms
     */
    export default {
        name: 'BaseFormActions',
        mixins: [BaseFormEventsMixin],
        props: {
            /**
             * Toggle the visibility of the primary button
             */
            hidePrimaryButton: {
                type: Boolean,
                default: false
            },
            /**
             * Toggle the visibility of the secondary button
             */
            hideSecondaryButton: {
                type: Boolean,
                default: false
            },
            /**
             * Toggle the loading state
             */
            loading: {
                type: Boolean,
                default: false
            },
            /**
             * The color of the primary button
             */
            primaryButtonColor: {
                type: String,
                default: 'primary'
            },
            /**
             * Toggle the disabled state of the primary button
             */
            primaryButtonDisabled: {
                type: Boolean,
                default: false
            },
            /**
             * The icon of the primary button
             */
            primaryButtonIcon: {
                type: String,
                default: 'mdi-send'
            },
            /**
             * The text of the primary button
             */
            primaryButtonText: {
                type: String,
                default: 'Submit'
            },
            /**
             * The color of the secondary button
             */
            secondaryButtonColor: {
                type: String,
                default: 'secondary'
            },
            /**
             * Toggle the disabled state of the secondary button
             */
            secondaryButtonDisabled: {
                type: Boolean,
                default: false
            },
            /**
             * The icon of the secondary button
             */
            secondaryButtonIcon: {
                type: String,
                default: 'mdi-close'
            },
            /**
             * The text of the secondary button
             */
            secondaryButtonText: {
                type: String,
                default: 'Close'
            }
        }
    }
</script>
