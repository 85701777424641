<template>
    <!-- RsvpForm.vue -->
    <v-card width="100%" class="mx-auto" flat>
        <v-card-title class="text-h5 accent--text py-0">
            {{ formData.name }}
        </v-card-title>
        <!-- Form -->
        <base-form-container :loading="loading">
            <v-form v-model="valid" @submit.prevent>
                <!-- Guests -->
                <v-list class="py-0">
                    <v-subheader>
                        {{ $t('confirm_guests') }}
                        <v-spacer></v-spacer>
                        {{ totals.attending }} / {{totals.total}}
                    </v-subheader>
                    <div class="body-1 px-4 secondary--text">
                        {{ $t('confirm_description') }}
                    </div>
                    <template v-for="(guest, index) in formData.guests">
                        <v-list-item :key="guest.name" @click="toggleAttending(index)">
                            <v-list-item-title>{{ guest.name }}</v-list-item-title>
                            <v-list-item-action>
                                <v-checkbox
                                    color="success"
                                    hide-details
                                    :input-value="guest.attending"
                                    @input="toggleAttending(index)"
                                ></v-checkbox>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider :key="`div_${guest.name}`"></v-divider>
                    </template>
                    <v-list-item>
                        <v-spacer></v-spacer>
                        <v-btn small color="error" @click="handleNotAttending">
                            {{ $t('not_attending') }}
                        </v-btn>
                    </v-list-item>
                </v-list>
                <!-- Music Requests -->
                <v-textarea
                    class="mt-6"
                    outlined
                    :label="$t('music_requests')"
                    :placeholder="$t('music_requests_placeholder')"
                    persistent-placeholder
                    v-model="formData.music_requests"
                ></v-textarea>
                <!-- Dietary Requirements -->
                <v-textarea
                    outlined
                    :label="$t('dietary_requirements')"
                    :placeholder="$t('dietary_requirements_placeholder')"
                    persistent-placeholder
                    v-model="formData.dietary_requirements"
                ></v-textarea>
                <!-- Additional Information -->
                <v-textarea
                    outlined
                    :label="$t('additional_info')"
                    :placeholder="$t('additional_info_placeholder')"
                    persistent-placeholder
                    v-model="formData.additional_information"
                ></v-textarea>
            </v-form>
        </base-form-container>

        <!-- Form Actions -->
        <v-card-actions class="pa-0">
            <v-btn block color="primary" :loading="saving" :disabled="!canSubmit" @click="handleSubmit">
                {{ $t('rsvp') }}
                <v-icon class="ml-1" small>mdi-send</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<i18n>
{
    "en": {
        "confirm_guests": "Confirm guests:",
        "confirm_description": "Please select the guests that will be attending",
        "music_requests": "Music Requests",
        "music_requests_placeholder": "Let us know some songs you want to hear",
        "dietary_requirements": "Dietary Requirements",
        "dietary_requirements_placeholder": "Let us know if anyone in your party has dietary requirements",
        "additional_info": "Additional Info",
        "additional_info_placeholder": "Anything else that we should know?",
        "not_attending": "We cannot attend",
        "confirm_not_attending": "Are you sure?"
    },
    "es": {
        "confirm_guests": "Confirmar invitados:",
        "confirm_description": "Por favor selecciona quién asistirá a la boda:",
        "music_requests": "Solicitudes de música",
        "music_requests_placeholder": "¿Qué canciones te gustaría escuchar?",
        "dietary_requirements": "Requisitos Dieteticos",
        "dietary_requirements_placeholder": "¿Eres alérgico a algún alimento?",
        "additional_info": "Información Adicional",
        "additional_info_placeholder": "¿Algo más que debamos saber?",
        "not_attending": "No podemos asistir",
        "confirm_not_attending": "¿Estas seguro?"
    }
}
</i18n>

<script>
    // Mixins
    import BaseFormMixin from '@/mixins/base-form.mixin'
    import {pluck} from 'underscore'

    export default {
        name: 'RsvpForm',
        mixins: [BaseFormMixin],
        data: () => ({
            formData: {
                name: null,
                guests: [],
                music_requests: null,
                dietary_requirements: null,
                additional_information: null
            }
        }),
        computed: {
            totals () {
                const vm = this

                return {
                    attending: pluck(vm.formData.guests, 'attending')?.reduce((a, b) => a + b, 0),
                    total: vm.formData.guests.length
                }
            }
        },
        methods: {
            handleNotAttending () {
                if (confirm(this.$t('confirm_not_attending'))) {
                    /**
                     * @event submit:not-attending
                     */
                    this.$emit('submit:not-attending', this.formData)
                }
            },
            toggleAttending (index) {
                const vm = this
                const guest = vm.formData.guests[index]

                vm.formData.guests.splice(index, 1, {
                    ...guest,
                    attending: !guest.attending
                })
            }
        }
    }
</script>
