<template>
    <!-- BaseFormActionsContainer.vue -->
    <v-container fluid pt-0 px-1>
        <v-card-actions class="align-end">
            <!-- @slot start -->
            <slot v-if="!loading" name="start"></slot>
            <v-spacer></v-spacer>
            <v-skeleton-loader
                :loading="loading"
                type="actions"
            >
                <slot></slot>
            </v-skeleton-loader>
        </v-card-actions>
    </v-container>
</template>

<script>
    /**
     * Container for actions buttons in forms
     */
    export default {
        name: 'BaseFormActionsContainer',
        props: {
            /**
             * Toggle the skeleton loader
             */
            loading: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
