import {omit} from 'underscore'
// Mixins
import BaseCardWidthMixin from '@/mixins/base-card-width.mixin'
import BaseFormEventsMixin from '@/mixins/base-form-events.mixin'
import BaseValidationMixin from '@/mixins/base-validation.mixin'
// Components
import BaseFormActionsContainer from '@/components/BaseFormActionsContainer'
import BaseFormActions from '@/components/BaseFormActions'
import BaseFormContainer from '@/components/BaseFormContainer'
import BaseFormToolbar from '@/components/BaseFormToolbar'

export default {
    mixins: [BaseFormEventsMixin, BaseValidationMixin, BaseCardWidthMixin],
    data: () => ({
        valid: false
    }),
    props: {
        /**
         * The error object i.e. {message: 'An error occurred'}
         */
        error: {
            type: Object,
            default: undefined
        },
        /**
         * Toggle the skeleton loader
         */
        loading: {
            type: Boolean,
            default: false
        },
        /**
         * Toggle the submit button loading state
         */
        saving: {
            type: Boolean,
            default: false
        },
        /**
         * The form data
         */
        item: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        BaseFormActionsContainer,
        BaseFormActions,
        BaseFormContainer,
        BaseFormToolbar
    },
    computed: {
        canSubmit () {
            const vm = this
            return !vm.loading && vm.valid
        }
    },
    methods: {
        handleSubmit () {
            const vm = this
            if (vm.canSubmit) {
                this.$emit('submit', omit(vm.formData, (val) => {
                    return val == null || typeof val === 'undefined'
                }))
            }
        },
        setFormData () {
            const vm = this
            vm.formData = {...vm.formData, ...vm.item}
        }
    },
    watch: {
        item () {
            this.setFormData()
        }
    },
    created () {
        this.setFormData()
    }
}
