const DARK = 'dark'
const LIGHT = 'light'

const DARK_THRESHOLD = 180

/**
 * @mixin
 */
export default {
    determineColorType (hexCode, darkThreshold = DARK_THRESHOLD) {

        darkThreshold = parseInt(darkThreshold)
        hexCode = +('0x' + hexCode.slice(1)
            .replace(hexCode.length < 5 && /./g, '$&$&'))

        const rgb = {
            red: hexCode >> 16,
            green: hexCode >> 8 & 255,
            blue: hexCode & 255
        }

        // HSP equation from http://alienryderflex.com/hsp.html
        // brightness = sqrt((0.299 * R^2) + (0.587 * G^2) + (0.114 * B^2))
        const hsp = Math.sqrt(
            0.299 * Math.pow(rgb.red, 2) +
            0.587 * Math.pow(rgb.green, 2) +
            0.114 * Math.pow(rgb.blue, 2)
        )

        return hsp > darkThreshold ? LIGHT : DARK
    },
    isColorDark (hexCode, darkThreshold = DARK_THRESHOLD) {
        return this.determineColorType(hexCode, darkThreshold) === DARK
    },
    isColorLight (hexCode, darkThreshold = DARK_THRESHOLD) {
        return this.determineColorType(hexCode, darkThreshold) === LIGHT
    }
}
