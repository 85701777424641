<template>
    <!-- Invitation.vue -->
    <div class="content">
        <div class="envelope"></div>
        <v-card class="letter" flat @click="handleClickConfirm">
            <div>
                <v-img :src="imgLink">
                    <v-btn
                        id="download"
                        target="_blank"
                        :href="imgLink"
                        download="SantiXiomi2021.jpg"
                        fab
                        small
                        depressed
                        class="float-right confirm-button"
                        color="primary"
                        @click.stop
                    >
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                </v-img>
                <v-container class="fill-height" fluid pa-0>
                    <v-row no-gutters class="fill-height">
                        <v-col align-self="end">
                            <v-card flat class="confirm-button" color="transparent">
                                <v-card-actions class="justify-end pa-1">
                                    <v-btn elevation="5" block color="primary" @click="handleClickConfirm">
                                        {{ $t('rsvp') }}
                                        <v-icon class="ml-1" small>mdi-send</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-card>
    </div>
</template>

<script>
    import gsap from 'gsap'
    import CSSPlugin from 'gsap/CSSPlugin'
    import CSSRulePlugin from 'gsap/CSSRulePlugin'

    gsap.registerPlugin(CSSPlugin, CSSRulePlugin)

    export default {
        name: 'Invitation',
        data: () => ({
            timeline: gsap.timeline({paused: true})
        }),
        computed: {
            imgLink () {
                return this.$i18n.locale === 'es'
                    ? '/img/invitacion.jpg'
                    : '/img/invitation.jpg'
            }
        },
        methods: {
            handleClickConfirm () {
                /**
                 * this.$emit('click:confirm')
                 */
                this.$emit('click:confirm')
            },
            openCard () {
                const vm = this
                const timeline = vm.timeline
                const flap = CSSRulePlugin.getRule('.envelope:before')

                timeline
                    .to(flap, {
                        duration: 1,
                        cssRule: {
                            rotateX: 180,
                            zIndex: 10
                        }
                    })
                    .to('.letter', {
                        translateY: -200,
                        duration: 0.9,
                        ease: 'back.inOut(1.5)',
                        height: 300
                    })
                    .addLabel('letterOut')
                    .to('.letter', {
                        height: 'unset',
                        duration: 0.9,
                        translateZ: vm.$vuetify.breakpoint.lgAndUp ? 200 : undefined
                    }, 'letterOut')
                    .to('.envelope', {
                        translateY: 250,
                        opacity: 0,
                        duration: 0.5
                    }, 'letterOut')
                    .to('.confirm-button', {
                        opacity: 1,
                        duration: 0.5
                    })
                    .play()
            }
        },
        created () {
            fetch(this.imgLink)
                .then((response) => {
                    if (response.status === 200) {
                        response.blob().then((blob) => {
                            const objectURL = window.URL.createObjectURL(blob)
                            document.getElementById('download').href = objectURL
                        })
                    }
                })
        },
        mounted () {
            this.openCard()
        }
    }
</script>

<style type="text/scss" lang="scss">
    :root {
        --base: #9cb9d1;
        --base-light: #9cb9d1;
        --base-dark: #9cb9d1;
        --shadow: #9cb9d1;
        --letter: #9cb9d1;
    }

    .invite {
        display: none;
    }

    .confirm-button {
        opacity: 0;
    }

    .content {
        position: relative;
        perspective: 600px;
    }

    .letter {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 280px;
        height: 160px;
        overflow: hidden;
        z-index: 15;
    }

    .envelope {
        position: relative;
        width: 300px;
        height: 180px;
        background: linear-gradient(#cccbd7 0.5px, var(--base-light) 0.5px);
        cursor: pointer;
    }

    .envelope::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 300px;
        border-top: 115px solid #7ba6cb;
        border-left: 150px solid transparent;
        border-right: 150px solid transparent;
        box-sizing: border-box;
        z-index: 30;
        transform-origin: top;
    }

    .envelope::after {
        content: '';
        position: absolute;
        top: 0;
        width: 300px;
        height: 180px;
        z-index: 25;
        background: //bottom-right
            linear-gradient(30deg, var(--base-dark) 47%, var(--shadow) 50%, var(--base) 50%) 150px 90px/ 150px 90px no-repeat,
                //top-left
            linear-gradient(31deg, var(--base) 49%, var(--shadow) 50%, transparent 50%) 0px 0px/ 152px 90px no-repeat,
                //bottom-left
            linear-gradient(150deg, var(--base) 50%, var(--shadow) 50%, var(--base-dark) 53%) 0px 90px/ 151px 90px no-repeat,
                //top-right
            linear-gradient(148.7deg, transparent 50%, var(--shadow) 50%, var(--base) 51%) 150px 0px/ 150px 90px no-repeat;
    }
</style>
