<template>
    <!-- BaseFormContainer.vue -->
    <v-container fluid pb-0 px-0>
        <v-row class="no-gutters">
            <!-- Form -->
            <v-col cols="12">
                <v-skeleton-loader
                    :loading="loading"
                    tile
                    type="list-item-avatar"
                >
                    <slot></slot>
                </v-skeleton-loader>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    /**
     * Container component and skeleton loader for forms
     */
    export default {
        name: 'BaseFormContainer',
        props: {
            /**
             * Toggle the skeleton loader
             */
            loading: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
