import {isEmpty, isString} from 'underscore'

/**
 * @mixin
 */
export default {
    computed: {
        validationRules () {
            return {
                generic: {
                    required: v => {
                        v = isString(v) ? v.trim() : v
                        return !isEmpty(v) || typeof v === 'number' || 'Required'
                    },
                    alphanumeric: v => isEmpty(v) || (!!v.match(/^[0-9a-z\s]+$/i)) || 'Field must only contain alphanumeric characters and spaces',
                    integersOnly: v => isEmpty(v) || /^\d+$/.test(v) || 'Positive integer values only',
                    minInt: min => v => (v && (parseInt(v) >= parseInt(min))) || `Minimum value allowed is ${min}`,
                    maxInt: max => v => (v && (parseInt(v) <= parseInt(max))) || `Maximum value allowed is ${max}`
                },
                email: {
                    valid: v => isEmpty(v) || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Email must be valid.  Ensure it has an \'@\' and a \'.\' character',
                    minLength: v => isEmpty(v) || v.length >= 6 || 'Email must be at least 6 characters long'
                },
                phoneNumber: {
                    noLetters: v => isEmpty(v) || !/[\D]/.test(v.slice(1)) || 'Phone number must only contain digits'
                }
            }
        }
    }
}
