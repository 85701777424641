/**
 * Base form events mixin which handles primary and secondary form events
 * @mixin
 */
export default {
    props: {
        /**
         *  The primary event emitted by the form
         */
        primaryFormEvent: {
            type: String,
            default: 'submit'
        },
        /**
         *  The secondary event emitted by the form
         */
        secondaryFormEvent: {
            type: String,
            default: 'close'
        }
    },
    methods: {
        handlePrimaryFormEvent (data = null) {
            /**
             * @event submit
             * @type {any}
             */
            this.$emit(this.primaryFormEvent, data)
        },
        handleSecondaryFormEvent (data = null) {
            /**
             * @event close
             * @type {any}
             */
            this.$emit(this.secondaryFormEvent, data)
        }
    }
}
