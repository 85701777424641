<template>
    <!-- BaseFormToolbar.vue -->
    <v-skeleton-loader
        :loading="loading"
        height="48"
        type="image"
    >
        <v-toolbar :color="color" :dark="isToolbarDark" :elevation="elevation" dense flat>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <template v-if="!hideCloseIcon">
                <v-spacer></v-spacer>
                <v-btn icon @click="handleClose">
                    <v-icon>{{ closeIcon }}</v-icon>
                </v-btn>
            </template>
        </v-toolbar>
    </v-skeleton-loader>
</template>

<script>
    import colorUtil from '@/utils/color'

    /**
     * Toolbar component with skeleton loader to be used in forms
     */
    export default {
        name: 'BaseFormToolbar',
        props: {
            /**
             * The color of the toolbar
             */
            color: {
                type: String,
                default: 'secondary'
            },
            /**
             * The close icon
             */
            closeIcon: {
                type: String,
                default: 'mdi-close'
            },
            /**
             * raises the toolbar using css
             */
            elevation: {
                type: Number,
                default: 1
            },
            /**
             * Toggle close icon visibility
             */
            hideCloseIcon: {
                type: Boolean,
                default: false
            },
            /**
             * Toggle the skeleton loader
             */
            loading: {
                type: Boolean,
                default: false
            },
            /**
             * The title of the toolbar
             */
            title: {
                type: String,
                default: ''
            }
        },
        computed: {
            isToolbarDark () {
                const vm = this
                const theme = vm.$vuetify.theme.isDark
                    ? vm.$vuetify.theme.defaults.dark
                    : vm.$vuetify.theme.defaults.light

                return colorUtil.isColorDark(theme[vm.color] || vm.color)
            }
        },
        methods: {
            handleClose () {
                /**
                 * @event close
                 */
                this.$emit('close')
            }
        }
    }
</script>
