<template>
    <!-- Invite.vue -->
    <v-container fluid :class="!showForm || confirmationMessage ? 'fill-height' : ''" v-if="!loading">
        <v-row no-gutters class="justify-center align-center">
            <v-col v-if="confirmationMessage">
                <v-card max-width="500" class="mx-auto" flat>
                    <v-card-title class="justify-center primary--text text-h5">
                        {{ $t('thanks') }}
                    </v-card-title>
                    <v-card-text class="text-subtitle-1 text-center">
                        {{ confirmationMessage }}
                    </v-card-text>
                    <v-card-actions>
                        <v-btn block color="primary" @click="handleClose">
                            {{ $t('close') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <envelope
                v-else-if="!showForm"
                @click:confirm="showForm = true"
            ></envelope>
            <rsvp-form
                v-else
                :item="invitation"
                @submit="handleUpdateInvitation"
                @submit:not-attending="handleNotAttending"
            ></rsvp-form>
        </v-row>
    </v-container>
</template>

<i18n>
{
    "en": {
        "thanks": "Thank You!",
        "success": "Thank you for confirming! We look forward to sharing our special day with you!",
        "sorry": "We are sorry you cannot share our special day with us!",
        "close": "Close"
    },
    "es": {
        "thanks": "Gracias!",
        "success": "¡Gracias por confirmar! ¡Esperamos poder compartir nuestro día especial contigo!",
        "sorry": "¡Sentimos que no puedas compartir nuestro día especial con nosotros!",
        "close": "Cerrar"
    }
}
</i18n>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import Envelope from '@/components/Invitation'
    import RsvpForm from '@/components/RsvpForm'

    const CONFIRMED = 'CONFIRMED'
    const DENIED = 'DENIED'

    export default {
        name: 'Invite',
        data: () => ({
            loading: true,
            showForm: false,
            confirm: null
        }),
        props: {
            id: {
                type: String,
                required: true
            }
        },
        components: {
            Envelope,
            RsvpForm
        },
        computed: {
            ...mapGetters('invitations', [
                'invitation'
            ]),
            confirmationMessage () {
                const vm = this

                switch (vm.confirm) {
                    case CONFIRMED:
                        return vm.$t('success')
                    case DENIED:
                        return vm.$t('sorry')
                    default:
                        return null
                }
            }
        },
        methods: {
            ...mapActions('invitations', [
                'showInvitation',
                'updateInvitation'
            ]),
            handleClose () {
                this.$router.push({path: '/'})
            },
            handleUpdateInvitation (data, confirm = CONFIRMED) {
                const vm = this
                vm.updateInvitation({
                    ...data,
                    confirmed_at: new Date().toISOString()
                })
                vm.confirm = confirm
            },
            handleNotAttending ({id, guests}) {
                guests = (guests || [])

                this.handleUpdateInvitation({
                    id,
                    guests: guests.map(guest => ({
                        ...guest,
                        attending: false
                    }))
                }, DENIED)
            }
        },
        async created () {
            try {
                await this.showInvitation(this.id)
                this.loading = false
            } catch (e) {
                if (e.status === 404) {
                    this.handleClose()
                }
            }
        }
    }
</script>
